import { Module } from 'vuex'
import { ISession } from '@/interface'
import Session from './session'
import mutations from '@/lib/store/modules/session/mutations'
import actions from '@/lib/store/modules/session/actions'
import getters from '@/lib/store/modules/session/getters'

export default <Module<ISession, unknown>>{
  state: new Session(),
  mutations,
  actions,
  getters
}
