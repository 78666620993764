import { GetterTree } from 'vuex'
import Session from '@/lib/store/modules/session/session'
import { IIdCard } from '@/interface'

export type Getters = {
  getId (state: Session): IIdCard
}

export default <GetterTree<Session, Session> & Getters>{
  getId (state: Session): IIdCard {
    return state.id_cards[state.id_cards_index]
  }
}
