import { BillContext } from '@/app/BillContext'

export interface SyncState {
  fetching: number,
  all: number,
  processed: number
}

export default class Cashier {
  sync: SyncState
  stack: Map<string, BillContext>
  isTicketing: boolean

  constructor () {
    this.sync = {
      all: 0,
      fetching: 0,
      processed: 0
    }
    this.stack = new Map<string, BillContext>()
    this.isTicketing = false
  }

  ts (): string {
    return ''
  }
}
