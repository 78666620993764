import request from '@/lib/request'
import { ApiInterface, ApiInterfaceMatch, ApiMock, ILoginForm } from '@/api/typings'
import { AxiosRequestConfig } from 'axios'
import { ISession, IToken } from '@/interface'

export class ApiSession {
  /**
   * 发送验证码
   * @param pn 手机号
   */
  static smsCaptcha (pn: string): Promise<unknown> {
    return request.request<unknown, unknown, unknown>({
      url: ApiInterface.SmsCaptcha,
      method: 'post',
      data: { phone_number: pn }
    } as AxiosRequestConfig)
  }

  static smsCaptchaMock: ApiMock = [ApiInterfaceMatch.SmsCaptcha, 'post', {}]

  /**
   * 验证码登录
   * @param form
   */
  static login (form: ILoginForm): Promise<ISession> {
    return request.request<unknown, ISession, unknown>({
      url: ApiInterface.Login,
      method: 'post',
      data: form
    } as AxiosRequestConfig)
  }

  /**
   * 微信登录
   * @param code
   */
  static wxAuth (code: string): Promise<ISession> {
    return request.request<unknown, ISession, unknown>({
      url: ApiInterface.WxAuth,
      params: { code: code }
    } as AxiosRequestConfig)
  }

  /**
   * Token刷新
   * @param token
   */
  static tokenRefresh (token: IToken): Promise<IToken> {
    return request.request<unknown, IToken, unknown>({
      url: ApiInterface.TokenRefresh,
      method: 'put',
      data: token
    } as AxiosRequestConfig)
  }
}
