import {
  ICacheBill,
  ICacheCommodity,
  ICacheMemberCards,
  ICacheUser, ICacheVoucher,
  IIdCard, IShop,
  SellType,
  Sex,
  ShopRole
} from '@/interface'
import { sync } from '@/api/sync'
import db from '@/lib/db'
import store from '@/lib/store'
import { StoreCashierMutations } from '@/lib/store/modules/cashier/mutations'
import SyncUser = sync.SyncUser
import SyncCommodity = sync.SyncCommodity
import SyncMemberPocket = sync.SyncMemberPocket
import SyncBill = sync.SyncBill
import SyncMemberVoucher = sync.SyncMemberVoucher
import request from '@/lib/request'
import { ApiInterface } from '@/api/typings'
import { AxiosRequestConfig } from 'axios'
import { IPublishPacket } from 'mqtt-packet'
import { StoreSessionMutations } from '@/lib/store/modules/session/mutations'

const channel = new BroadcastChannel('sync_event')

class ApiSync {
  sync (id: IIdCard): Promise<unknown> {
    if (store.state.session.token.expire < new Date()) {
      return new Promise<unknown>((resolve) => resolve(''))
    }
    store.commit(StoreCashierMutations.FetchBegin, 5)
    return Promise.allSettled([
      this.bills(id.shop.sid),
      this.users(id.shop.sid),
      this.commodities(id.shop.sid),
      this.memberPockets(id.shop.sid),
      this.vouchers(id.shop.sid)
    ])
  }

  handleUserChange (packet: IPublishPacket): void {
    const i = ApiSync.userConvert(SyncUser.deserializeBinary(packet.payload as Buffer))
    i.update_at = new Date(0)
    db.users.put(i).finally(() => channel.postMessage(packet))
    store.commit(StoreSessionMutations.onCreatorChange, i)
  }

  private static userConvert (i: SyncUser): ICacheUser {
    return <ICacheUser>{
      arrears: i.arrears ?? 0,
      head_img_url: i.head_img_url,
      last_buy_time: new Date((i.last_buy_time ?? 1e6) / 1e6),
      name_key: i.name_key ?? '',
      nick_name: i.nick_name ?? '',
      phone_number: i.phone_number ?? '',
      privilege_data: (i.privilege_data ?? false) ? 1 : 0,
      real_name: i.real_name ?? '',
      role: i.role as ShopRole,
      sex: i.sex ?? Sex.unknown,
      sid: i.sid ?? 0,
      uid: i.uid ?? 0,
      update_at: new Date((i.update_at ?? 1e6) / 1e6),
      wx_open_id: i.wx_open_id,
      delete_at: i.delete_at ? new Date(i.delete_at / 1e6) : undefined
    }
  }

  private users (sid: number): Promise<unknown> {
    return db.users.where({ sid: sid }).sortBy('update_at')
      .then(arr => {
        return request.request<unknown, ArrayBuffer>({
          url: ApiInterface.SyncUser,
          params: {
            at: arr.length > 0 ? arr[arr.length - 1].update_at.valueOf() * 1e6 : 1,
            sid: sid
          },
          responseType: 'arraybuffer'
        } as AxiosRequestConfig).then((rs: ArrayBuffer): Promise<ICacheUser[]> => {
          return new Promise(resolve => {
            const arr = sync.SyncUsers.deserialize(new Uint8Array(rs)).list
            store.commit(StoreCashierMutations.SyncGot, arr.length)
            resolve(arr.map((i): ICacheUser => ApiSync.userConvert(i)))
          })
        }).then((list: ICacheUser[]): Promise<unknown> => {
          return Promise.all(list.map(u => db.users.put(u)
            .finally(() => store.commit(StoreCashierMutations.SyncHandleOnce))))
        }).finally(() => {
          store.commit(StoreCashierMutations.FetchEnd)
        })
      })
  }

  handleCommodityChange (packet: IPublishPacket): void {
    const i = ApiSync.commodityConvert(SyncCommodity.deserializeBinary(packet.payload as Buffer))
    i.update_at = new Date(0)
    db.commodities.put(i).finally(() => channel.postMessage(packet))
  }

  static commodityConvert (i: SyncCommodity): ICacheCommodity {
    return {
      commodity: i.commodity ?? '',
      frequency: i.frequency ?? 0,
      gift_amount: i.gift_amount ?? 0,
      months: i.months ?? 0,
      name_key: i.name_key ?? '',
      sell_type_id: i.sell_type_id ?? SellType.Project,
      sid: i.sid ?? 0,
      snid: i.snid ?? 0,
      unit_price: i.unit_price ?? 0,
      update_at: new Date((i.update_at ?? 1e6) / 1e6),
      volume: i.volume ?? 0,
      delete_at: i.delete_at ? new Date(i.delete_at / 1e6) : undefined
    }
  }

  private commodities (sid: number): Promise<unknown> {
    return db.commodities.where({ sid: sid }).sortBy('update_at')
      .then(arr => {
        return request.request<unknown, ArrayBuffer>({
          url: ApiInterface.SyncCommodities,
          params: {
            at: arr.length > 0 ? arr[arr.length - 1].update_at.valueOf() * 1e6 : 1,
            sid: sid
          },
          responseType: 'arraybuffer'
        } as AxiosRequestConfig).then((rs: ArrayBuffer): Promise<ICacheCommodity[]> => {
          const arr = sync.SyncCommodities.deserialize(new Uint8Array(rs)).list
          store.commit(StoreCashierMutations.SyncGot, arr.length)
          return new Promise(resolve => {
            resolve(arr.map((i): ICacheCommodity => ApiSync.commodityConvert(i)))
          })
        }).then((list: ICacheCommodity[]): Promise<unknown> => {
          return Promise.all(list.map(u => db.commodities.put(u)
            .finally(() => store.commit(StoreCashierMutations.SyncHandleOnce))))
        }).finally(() => {
          store.commit(StoreCashierMutations.FetchEnd)
        })
      })
  }

  handleMemberCardChange (packet: IPublishPacket): void {
    const i = ApiSync.memberCardConvert(SyncMemberPocket.deserializeBinary(packet.payload as Buffer))
    i.update_at = new Date(0)
    db.memberCards.put(i).finally(() => channel.postMessage(packet))
  }

  static memberCardConvert (i: SyncMemberPocket): ICacheMemberCards {
    return {
      bid: i.bid ?? 0,
      buy_types_limit: i.buy_types_limit ?? [],
      card_name: i.card_name ?? '',
      creator_uid: i.creator_uid ?? 0,
      dc_pay_range: JSON.parse(i.dc_pay_range ?? '{}'),
      expire_at: new Date((i.expire_at ?? 1e6) / 1e6),
      frequency: i.frequency ?? 0,
      frequency_balance: i.frequency_balance ?? 0,
      integral: Math.round(i.integral ?? 0),
      money_balance: i.money_balance ?? 0,
      mpid: i.mpid ?? 0,
      sell_type_id: i.sell_type_id ?? SellType.Project,
      sid: i.sid ?? 0,
      uid: i.uid ?? 0,
      update_at: new Date((i.update_at ?? 1e6) / 1e6),
      use_percent: i.use_percent ?? 100,
      delete_at: i.delete_at ? new Date(i.delete_at / 1e6) : undefined
    }
  }

  private memberPockets (sid: number): Promise<unknown> {
    return db.memberCards.where({ sid: sid }).sortBy('update_at')
      .then(arr => {
        return request.request<unknown, ArrayBuffer>({
          url: ApiInterface.SyncMemberPockets,
          params: {
            at: arr.length > 0 ? arr[arr.length - 1].update_at.valueOf() * 1e6 : 1,
            sid: sid
          },
          responseType: 'arraybuffer'
        } as AxiosRequestConfig).then((rs: ArrayBuffer): Promise<ICacheMemberCards[]> => {
          return new Promise(resolve => {
            const arr = sync.SyncMemberPockets.deserialize(new Uint8Array(rs)).list
            store.commit(StoreCashierMutations.SyncGot, arr.length)
            resolve(arr.map((i): ICacheMemberCards => ApiSync.memberCardConvert(i)))
          })
        }).then((list: ICacheMemberCards[]): Promise<unknown> => {
          return Promise.all(list.map(u => db.memberCards.put(u).finally(() => store.commit(StoreCashierMutations.SyncHandleOnce))))
        }).finally(() => {
          store.commit(StoreCashierMutations.FetchEnd)
        })
      })
  }

  handleBillChange (packet: IPublishPacket): void {
    const i = ApiSync.billConvert(SyncBill.deserializeBinary(packet.payload as Buffer))
    i.update_at = new Date(1)
    db.bills.put(i).finally(() => channel.postMessage(packet))
  }

  static billConvert (i: SyncBill): ICacheBill {
    return {
      arrears: i.arrears ?? 0,
      attach_pic: i.attach_pic ?? '',
      buyer_uid: i.buyer_uid ?? 0,
      create_at: new Date((i.create_at ?? 1e6) / 1e6),
      creator_uid: i.creator_uid ?? 0,
      delete_at: i.delete_at ? new Date((i.delete_at ?? 1e6) / 1e6) : undefined,
      integral: Math.round(i.integral ?? 0),
      is_female: i.is_female ?? false,
      is_set: i.is_set ?? false,
      mark: i.mark ?? '',
      member_card_fees: i.member_card_fees ?? 0,
      member_card_id: i.member_card_id ?? 0,
      pay_fees: i.pay_fees ?? 0,
      repayment_bid: i.repayment_bid ?? 0,
      sell_type_id: i.sell_type_id ?? SellType.Project,
      sid: i.sid,
      update_at: new Date((i.update_at ?? 1e6) / 1e6),
      bid: i.bid ?? 0,
      open_bid: i.open_bid ?? ''
    }
  }

  private bills (sid: number): Promise<unknown> {
    return db.bills.where({ sid: sid }).sortBy('update_at')
      .then(arr => {
        return request.request<unknown, ArrayBuffer>({
          url: ApiInterface.SyncBills,
          params: {
            at: arr.length > 0 ? arr[arr.length - 1].update_at.valueOf() * 1e6 : 1,
            sid: sid
          },
          responseType: 'arraybuffer'
        } as AxiosRequestConfig).then((rs: ArrayBuffer): Promise<ICacheBill[]> => {
          return new Promise(resolve => {
            const arr = sync.SyncBills.deserialize(new Uint8Array(rs)).list
            store.commit(StoreCashierMutations.SyncGot, arr.length)
            resolve(arr.map((i): ICacheBill => ApiSync.billConvert(i)))
          })
        }).then((list: ICacheBill[]): Promise<unknown> => {
          return Promise.all(list.map(u => db.bills.put(u).finally(() => store.commit(StoreCashierMutations.SyncHandleOnce))))
        }).finally(() => {
          store.commit(StoreCashierMutations.FetchEnd)
        })
      })
  }

  handleVoucherChange (packet: IPublishPacket): void {
    const i = ApiSync.voucherConvert(SyncMemberVoucher.deserializeBinary(packet.payload as Buffer))
    i.update_at = new Date(0)
    db.vouchers.put(i).finally(() => channel.postMessage(packet))
  }

  static voucherConvert (i: SyncMemberVoucher): ICacheVoucher {
    return {
      delete_at: i.delete_at ? new Date((i.delete_at ?? 1e6) / 1e6) : undefined,
      expire_at: new Date((i.update_at ?? 1e6) / 1e6),
      mv_id: i.mv_id ?? 0,
      price: i.price ?? 0,
      sid: i.sid,
      title: i.title ?? '',
      uid: i.uid ?? 0,
      update_at: new Date((i.update_at ?? 1e6) / 1e6),
      voucher_id: i.voucher_id ?? 0,
      voucher_value: i.voucher_value ?? 0,
      write_off_at: i.delete_at ? new Date((i.delete_at ?? 1e6) / 1e6) : undefined
    }
  }

  private vouchers (sid: number): Promise<unknown> {
    return db.vouchers.where({ sid: sid }).sortBy('update_at')
      .then(arr => {
        return request.request<unknown, ArrayBuffer>({
          url: ApiInterface.SyncBills,
          params: {
            at: arr.length > 0 ? arr[arr.length - 1].update_at.valueOf() * 1e6 : 1,
            sid: sid
          },
          responseType: 'arraybuffer'
        } as AxiosRequestConfig).then((rs: ArrayBuffer): Promise<ICacheVoucher[]> => {
          return new Promise(resolve => {
            const arr = sync.SyncMemberVouchers.deserialize(new Uint8Array(rs)).list
            store.commit(StoreCashierMutations.SyncGot, arr.length)
            resolve(arr.map((i): ICacheVoucher => ApiSync.voucherConvert(i)))
          })
        }).then((list: ICacheVoucher[]): Promise<unknown> => {
          return Promise.all(list.map(u => db.vouchers.put(u).finally(() => store.commit(StoreCashierMutations.SyncHandleOnce))))
        }).finally(() => {
          store.commit(StoreCashierMutations.FetchEnd)
        })
      })
  }

  handleShopChange (packet: IPublishPacket): void {
    try {
      const s = JSON.parse(packet.payload as string)
      s.album = JSON.stringify(s.album)
      store.commit(StoreSessionMutations.onShopChange, s as IShop)
    } catch (e) {
      console.log(e)
    }
  }
}

export default new ApiSync()
