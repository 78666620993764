import { MutationTree } from 'vuex'
import Cashier from '@/lib/store/modules/cashier/cashier'
import { BillContext, Scene } from '@/app/BillContext'
import { IBillItem, IBillPay, IEmployee, SellType, Sex } from '@/interface'

export enum StoreCashierMutations {
  FetchBegin = 'fetchBegin',
  FetchEnd = 'fetchEnd',
  SyncGot = 'syncGot',
  SyncHandleOnce = 'syncHandleOnce',
  SyncComplete = 'syncComplete',
  NewBillContext = 'newBillContext',
  ClearBillContext = 'clearBillContext',
  SetSex = 'setSex',
  CashierSetItems = 'cashierSetItems',
  CashierSetPays = 'cashierSetPays',
  CashierSetEmployee = 'cashierSetEmployee',
  CashierSetIntegral = 'cashierSetIntegral',
  CashierNext = 'cashierNext',
  CashierPrevious = 'cashierPrevious',
  CashierTicketLoading = 'cashierTicketLoading'
}

export interface ISetSex {
  key: string
  sex: Sex
}

export type Payload = {
  key: string
  value: unknown
}

export default <MutationTree<Cashier>>{
  [StoreCashierMutations.FetchBegin] (state: Cashier, count: number) {
    state.sync.fetching = count
  },
  [StoreCashierMutations.FetchEnd] (state: Cashier) {
    state.sync.fetching -= 1
  },
  [StoreCashierMutations.SyncGot] (state: Cashier, count: number) {
    state.sync.all += count
  },
  [StoreCashierMutations.SyncHandleOnce] (state: Cashier) {
    state.sync.processed += 1
  },
  [StoreCashierMutations.SyncComplete] (state: Cashier) {
    state.sync.fetching = 0
    state.sync.all = 0
    state.sync.processed = 0
  },
  [StoreCashierMutations.NewBillContext] (state: Cashier, bc: BillContext) {
    state.stack.set(bc.id, bc)
  },
  [StoreCashierMutations.ClearBillContext] (state: Cashier, bc: BillContext) {
    state.stack.delete(bc.id)
  },
  [StoreCashierMutations.SetSex] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.member.sex = payload.value as Sex
    }
  },
  [StoreCashierMutations.CashierSetItems] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.bill.items = payload.value as IBillItem[]
      if (bc.bill.items.length > 0) {
        if ([SellType.CardRecharge, SellType.DiscountCard, SellType.SpecialPriceCard].includes(bc.bill.items[0].sell_type_id)) {
          bc.bill.cash_back = bc.bill.items.reduce((a, b) => a + b.gift_amount, 0)
        }
        if (bc.bill.items[0].sell_type_id === SellType.Redeem) {
          bc.bill.integral = -bc.bill.items.reduce((a, b) => a + b.integral, 0)
        }
      }
    }
  },
  [StoreCashierMutations.CashierSetPays] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.bill.pays = payload.value as IBillPay[]
      bc.bill.pay_fees = bc.bill.pays.filter(i => ![5, 6].includes(i.id)).reduce((a, b) => a + b.fee, 0)
    }
  },
  [StoreCashierMutations.CashierSetEmployee] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.bill.employees = payload.value as IEmployee[]
    }
  },
  [StoreCashierMutations.CashierSetIntegral] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.bill.integral = payload.value as number
    }
  },
  [StoreCashierMutations.CashierNext] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      bc.scenes.push(payload.value as Scene)
      window.scrollTo(0, 0)
    }
  },
  [StoreCashierMutations.CashierPrevious] (state: Cashier, payload: Payload) {
    const bc = state.stack.get(payload.key)
    if (bc) {
      if (bc.scenes.length > 1) {
        bc.scenes.pop()
      }
      window.scrollTo(0, 0)
    }
  },
  [StoreCashierMutations.CashierTicketLoading] (state: Cashier, loading: boolean) {
    state.isTicketing = loading
  }
}
