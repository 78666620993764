import { ActionContext, ActionTree } from 'vuex'
import Cashier from '@/lib/store/modules/cashier/cashier'
import { IBillRes, IIdCard } from '@/interface'
import ApiSync from '@/api/http/sync'
import { StoreCashierMutations } from '@/lib/store/modules/cashier/mutations'
import mqtt from '@/api/mqtt'
import { BillContext } from '@/app/BillContext'
import router from '@/lib/router'
import { ApiCashier } from '@/api/http/cashier'
import { StoreSessionMutations } from '@/lib/store/modules/session/mutations'

export enum StoreCashierActions {
  onJoin = 'onJoin',
  onTicketing = 'onTicketing'
}

const channel = new BroadcastChannel('sync')

export default <ActionTree<Cashier, unknown>>{
  [StoreCashierActions.onJoin] (context: ActionContext<Cashier, unknown>, id: IIdCard): void {
    context.commit(StoreSessionMutations.join, id)
    ApiSync.sync(id).finally(() => {
      context.commit(StoreCashierMutations.SyncComplete)
      channel.postMessage(JSON.stringify(id))
    })
    mqtt.subShopSync(id.shop.sid)
  },
  [StoreCashierActions.onTicketing] (context: ActionContext<Cashier, unknown>, bc: BillContext): void {
    context.commit(StoreCashierMutations.CashierTicketLoading, true)
    ApiCashier.bill(bc.bill).then((rs: IBillRes): void => {
      open('https://cashier.mywsy.cn/ticket/' + rs.open_bid, 'newwindow', 'height=600, width=400')
    }).finally(() => {
      context.commit(StoreCashierMutations.ClearBillContext, bc)
      context.commit(StoreCashierMutations.CashierTicketLoading, false)
      return router.push({ name: 'workspace' })
    })
  }
}
