import { ActionContext, ActionTree, Commit } from 'vuex'
import Session from '@/lib/store/modules/session/session'
import { ILoginForm } from '@/api/typings'
import { ISession, IToken } from '@/interface'
import { ApiSession } from '@/api/http/session'
import { StoreSessionMutations } from '@/lib/store/modules/session/mutations'
import mqtt from '@/api/mqtt'
import router from '@/lib/router'
import notification from 'ant-design-vue/es/notification'

export enum StoreSessionActions {
  doWxAuth = 'doWxAuth',
  doLogin = 'doLogin',
  doTokenRefresh = 'doTokenRefresh'
}

export default <ActionTree<Session, unknown>>{
  [StoreSessionActions.doWxAuth] ({ commit }, code: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiSession.wxAuth(code).then((res: unknown): void => {
        if ('headimgurl' in (res as { [key: string]: unknown })) {
          resolve(res)
        } else {
          const rs = res as ISession
          onLogin(resolve, commit, rs)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  [StoreSessionActions.doLogin] ({ commit }, info: ILoginForm): Promise<ISession> {
    return new Promise((resolve, reject) => {
      ApiSession.login(info).then((rs: ISession) => {
        onLogin(resolve, commit, rs)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [StoreSessionActions.doTokenRefresh] (context: ActionContext<ISession, unknown>): Promise<IToken> {
    if (context.state.token.expire.valueOf() - new Date().valueOf() > 60 * 1000) {
      return new Promise((resolve) => resolve(context.state.token))
    }
    if (context.state.token.token === '') {
      return new Promise((resolve) => resolve(context.state.token))
    }
    return new Promise((resolve, reject) => {
      ApiSession.tokenRefresh(context.state.token).then((rs: IToken) => {
        rs.expire = new Date(rs.expire)
        context.commit(StoreSessionMutations.tokenRefresh, rs)
        resolve(rs)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const onLogin = (resolve: (value: (ISession | PromiseLike<ISession>)) => void, commit: Commit, rs: ISession) => {
  if (rs.id_cards.length > 0) {
    rs.token.expire = new Date(rs.token.expire)
    mqtt.connect(rs.account.phone_number, rs.token.token)
    mqtt.subShopChange(rs.id_cards)
    commit(StoreSessionMutations.login, rs)
    resolve(rs)
  } else {
    router.push({ path: '/auth/download' }).catch(e => console.log(e))
    notification.warn({ message: '账号下没有门店' })
  }
}
