import { IAccount, IIdCard, ISession, IToken } from '@/interface'

export default class Session implements ISession {
  account: IAccount
  token: IToken
  id_cards: IIdCard[]
  id_cards_index: number

  constructor () {
    this.account = {
      open_aid: '',
      phone_number: ''
    }
    this.token = {
      expire: new Date(),
      token: ''
    }
    this.id_cards_index = -1
    this.id_cards = <IIdCard[]>[]
  }
}
