<template>
  <div class="footer">
    <div class="copyright">
      Copyright ©{{ new Date().getFullYear() }} 管店宝
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="less">
@import (reference) "~@/styles/app";

.footer {
  width: 100%;
  bottom: 0;
  padding: 0 16px;
  margin: 48px 0 24px;
  text-align: center;

  .links {
    margin-bottom: 8px;
    font-size: 14px;

    a {
      color: @text-color-secondary;
      transition: all 0.3s;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .copyright {
    color: @text-color-secondary;
    font-size: 14px;
  }
}
</style>
