import { RouteRecordRaw } from 'vue-router'
import store from '@/lib/store'

export default <Array<RouteRecordRaw>>[
  {
    path: '',
    redirect: { name: 'workspace' }
  },
  {
    path: 'workspace',
    name: 'workspace',
    meta: { requireAuth: true },
    component: () => import(/* webpackChunkName: "workspace" */ './WorkspaceView.vue')
  },
  {
    path: 'members',
    name: 'members',
    meta: { requireAuth: true },
    component: () => import(/* webpackChunkName: "workspace" */ './MembersView.vue')
  },
  {
    path: 'member/add',
    meta: { requireAuth: true },
    component: () => import(/* webpackChunkName: "workspace" */ './MemberAddView.vue')
  },
  {
    path: 'bill/:id',
    meta: { requireAuth: true },
    beforeEnter: (to, from, next) => {
      if (!store.state.cashier.stack.has(to.params.id as string)) {
        next({ name: 'notfound' })
      } else {
        next()
      }
    },
    component: () => import(/* webpackChunkName: "workspace" */ './CashierView.vue')
  },
  {
    path: 'my/bills',
    meta: { requireAuth: true },
    component: () => import(/* webpackChunkName: "workspace" */ './MyBillsView.vue')
  }
]
