import { MutationTree } from 'vuex'
import Session from '@/lib/store/modules/session/session'
import { ICacheUser, IIdCard, ISession, IShop, IToken } from '@/interface'

export enum StoreSessionMutations {
  login = 'login',
  tokenRefresh = 'tokenRefresh',
  logout = 'logout',
  join = 'join',
  onShopChange = 'onShopChange',
  onCreatorChange = 'onCreatorChange'
}

export default <MutationTree<Session>>{
  [StoreSessionMutations.login] (state: Session, res: ISession) {
    state.account.phone_number = res.account.phone_number
    state.token = res.token
    state.id_cards = res.id_cards
    state.id_cards_index = -1
  },
  [StoreSessionMutations.tokenRefresh] (state: Session, res: IToken) {
    state.token = res
  },
  [StoreSessionMutations.logout] (state: Session) {
    state.account.phone_number = ''
    state.token.token = ''
    state.token.expire = new Date()
    state.id_cards = []
    state.id_cards_index = -1
  },
  [StoreSessionMutations.join] (state: Session, idCard: IIdCard) {
    state.id_cards.forEach((id, index) => {
      if (id.shop.sid === idCard.shop.sid) state.id_cards_index = index
    })
  },
  [StoreSessionMutations.onShopChange] (state: Session, shop: IShop) {
    state.id_cards.forEach((id, i) => {
      if (id.shop.sid === shop.sid) {
        shop.expiration = new Date(shop.expiration)
        state.id_cards[i].shop = shop
      }
    })
  },
  [StoreSessionMutations.onCreatorChange] (state: Session, user: ICacheUser) {
    state.id_cards.forEach((id, i) => {
      if (id.creator.uid === user.uid) {
        state.id_cards[i].creator = user
      }
    })
  }
}
