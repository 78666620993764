import { createStore, Store as VuexStore } from 'vuex'
import session from './modules/session'
import cashier from './modules/cashier'
import { State } from './typings'
import { Getters } from '@/lib/store/modules/session/getters'

export type Store = Omit<VuexStore<State>, 'getters'> & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}

export default createStore<State>({
  modules: {
    session,
    cashier
  },
  strict: process.env.NODE_ENV !== 'production'
})
