import { Module } from 'vuex'
import mutations from '@/lib/store/modules/cashier/mutations'
import Cashier from '@/lib/store/modules/cashier/cashier'
import actions from '@/lib/store/modules/cashier/actions'

export default <Module<Cashier, unknown>>{
  state: new Cashier(),
  mutations,
  actions
}
