/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a services worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
      const broadcast = new BroadcastChannel('count-channel')
      broadcast.onmessage = (event: MessageEvent) => {
        console.log('BroadcastChannel =>', event)
        // const ts = new Date()
        // ApiSync.sync(9917).then((rs: unknown) => console.log('rs ->', typeof rs, new Date().valueOf() - ts.valueOf())).catch(err => console.log('err ->', err))
      }
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during services worker registration:', error)
    }
  })
}
