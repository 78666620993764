/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.11.2
 * source: sync.proto
 * git: https://github.com/thesayyn/protoc-gen-ts
 *  */
import * as pb_1 from "google-protobuf";
export namespace sync {
    export class SyncUsers extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncUser[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncUser, 1) as SyncUser[];
        }
        set list(value: SyncUser[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncUser.prototype.toObject>[];
        }) {
            const message = new SyncUsers({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncUser.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncUser.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncUser) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncUser) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncUsers {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncUsers();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncUser.deserialize(reader), SyncUser));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncUsers {
            return SyncUsers.deserialize(bytes);
        }
    }
    export class SyncUser extends pb_1.Message {
        constructor(data?: any[] | {
            uid?: number;
            sid?: number;
            role?: number;
            sex?: number;
            device_id?: string;
            phone_number?: string;
            real_name?: string;
            wx_open_id?: string;
            head_img_url?: string;
            nick_name?: string;
            privilege_data?: boolean;
            arrears?: number;
            delete_at?: number;
            name_key?: string;
            last_buy_time?: number;
            update_at?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("uid" in data && data.uid != undefined) {
                    this.uid = data.uid;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("role" in data && data.role != undefined) {
                    this.role = data.role;
                }
                if ("sex" in data && data.sex != undefined) {
                    this.sex = data.sex;
                }
                if ("device_id" in data && data.device_id != undefined) {
                    this.device_id = data.device_id;
                }
                if ("phone_number" in data && data.phone_number != undefined) {
                    this.phone_number = data.phone_number;
                }
                if ("real_name" in data && data.real_name != undefined) {
                    this.real_name = data.real_name;
                }
                if ("wx_open_id" in data && data.wx_open_id != undefined) {
                    this.wx_open_id = data.wx_open_id;
                }
                if ("head_img_url" in data && data.head_img_url != undefined) {
                    this.head_img_url = data.head_img_url;
                }
                if ("nick_name" in data && data.nick_name != undefined) {
                    this.nick_name = data.nick_name;
                }
                if ("privilege_data" in data && data.privilege_data != undefined) {
                    this.privilege_data = data.privilege_data;
                }
                if ("arrears" in data && data.arrears != undefined) {
                    this.arrears = data.arrears;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("name_key" in data && data.name_key != undefined) {
                    this.name_key = data.name_key;
                }
                if ("last_buy_time" in data && data.last_buy_time != undefined) {
                    this.last_buy_time = data.last_buy_time;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
            }
        }
        get uid() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set uid(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 2) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get role() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set role(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get sex() {
            return pb_1.Message.getField(this, 4) as number;
        }
        set sex(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get device_id() {
            return pb_1.Message.getField(this, 5) as string;
        }
        set device_id(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get phone_number() {
            return pb_1.Message.getField(this, 6) as string;
        }
        set phone_number(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get real_name() {
            return pb_1.Message.getField(this, 7) as string;
        }
        set real_name(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get wx_open_id() {
            return pb_1.Message.getField(this, 8) as string;
        }
        set wx_open_id(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get head_img_url() {
            return pb_1.Message.getField(this, 9) as string;
        }
        set head_img_url(value: string) {
            pb_1.Message.setField(this, 9, value);
        }
        get nick_name() {
            return pb_1.Message.getField(this, 10) as string;
        }
        set nick_name(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get privilege_data() {
            return pb_1.Message.getField(this, 11) as boolean;
        }
        set privilege_data(value: boolean) {
            pb_1.Message.setField(this, 11, value);
        }
        get arrears() {
            return pb_1.Message.getField(this, 12) as number;
        }
        set arrears(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 13) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get name_key() {
            return pb_1.Message.getField(this, 14) as string;
        }
        set name_key(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get last_buy_time() {
            return pb_1.Message.getField(this, 15) as number;
        }
        set last_buy_time(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 16) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        static fromObject(data: {
            uid?: number;
            sid?: number;
            role?: number;
            sex?: number;
            device_id?: string;
            phone_number?: string;
            real_name?: string;
            wx_open_id?: string;
            head_img_url?: string;
            nick_name?: string;
            privilege_data?: boolean;
            arrears?: number;
            delete_at?: number;
            name_key?: string;
            last_buy_time?: number;
            update_at?: number;
        }) {
            const message = new SyncUser({});
            if (data.uid != null) {
                message.uid = data.uid;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.role != null) {
                message.role = data.role;
            }
            if (data.sex != null) {
                message.sex = data.sex;
            }
            if (data.device_id != null) {
                message.device_id = data.device_id;
            }
            if (data.phone_number != null) {
                message.phone_number = data.phone_number;
            }
            if (data.real_name != null) {
                message.real_name = data.real_name;
            }
            if (data.wx_open_id != null) {
                message.wx_open_id = data.wx_open_id;
            }
            if (data.head_img_url != null) {
                message.head_img_url = data.head_img_url;
            }
            if (data.nick_name != null) {
                message.nick_name = data.nick_name;
            }
            if (data.privilege_data != null) {
                message.privilege_data = data.privilege_data;
            }
            if (data.arrears != null) {
                message.arrears = data.arrears;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.name_key != null) {
                message.name_key = data.name_key;
            }
            if (data.last_buy_time != null) {
                message.last_buy_time = data.last_buy_time;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            return message;
        }
        toObject() {
            const data: {
                uid?: number;
                sid?: number;
                role?: number;
                sex?: number;
                device_id?: string;
                phone_number?: string;
                real_name?: string;
                wx_open_id?: string;
                head_img_url?: string;
                nick_name?: string;
                privilege_data?: boolean;
                arrears?: number;
                delete_at?: number;
                name_key?: string;
                last_buy_time?: number;
                update_at?: number;
            } = {};
            if (this.uid != null) {
                data.uid = this.uid;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.role != null) {
                data.role = this.role;
            }
            if (this.sex != null) {
                data.sex = this.sex;
            }
            if (this.device_id != null) {
                data.device_id = this.device_id;
            }
            if (this.phone_number != null) {
                data.phone_number = this.phone_number;
            }
            if (this.real_name != null) {
                data.real_name = this.real_name;
            }
            if (this.wx_open_id != null) {
                data.wx_open_id = this.wx_open_id;
            }
            if (this.head_img_url != null) {
                data.head_img_url = this.head_img_url;
            }
            if (this.nick_name != null) {
                data.nick_name = this.nick_name;
            }
            if (this.privilege_data != null) {
                data.privilege_data = this.privilege_data;
            }
            if (this.arrears != null) {
                data.arrears = this.arrears;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.name_key != null) {
                data.name_key = this.name_key;
            }
            if (this.last_buy_time != null) {
                data.last_buy_time = this.last_buy_time;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.uid !== undefined)
                writer.writeInt64(1, this.uid);
            if (this.sid !== undefined)
                writer.writeInt64(2, this.sid);
            if (this.role !== undefined)
                writer.writeInt32(3, this.role);
            if (this.sex !== undefined)
                writer.writeInt32(4, this.sex);
            if (typeof this.device_id === "string" && this.device_id.length)
                writer.writeString(5, this.device_id);
            if (typeof this.phone_number === "string" && this.phone_number.length)
                writer.writeString(6, this.phone_number);
            if (typeof this.real_name === "string" && this.real_name.length)
                writer.writeString(7, this.real_name);
            if (typeof this.wx_open_id === "string" && this.wx_open_id.length)
                writer.writeString(8, this.wx_open_id);
            if (typeof this.head_img_url === "string" && this.head_img_url.length)
                writer.writeString(9, this.head_img_url);
            if (typeof this.nick_name === "string" && this.nick_name.length)
                writer.writeString(10, this.nick_name);
            if (this.privilege_data !== undefined)
                writer.writeBool(11, this.privilege_data);
            if (this.arrears !== undefined)
                writer.writeDouble(12, this.arrears);
            if (this.delete_at !== undefined)
                writer.writeInt64(13, this.delete_at);
            if (typeof this.name_key === "string" && this.name_key.length)
                writer.writeString(14, this.name_key);
            if (this.last_buy_time !== undefined)
                writer.writeInt64(15, this.last_buy_time);
            if (this.update_at !== undefined)
                writer.writeInt64(16, this.update_at);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncUser {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncUser();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.uid = reader.readInt64();
                        break;
                    case 2:
                        message.sid = reader.readInt64();
                        break;
                    case 3:
                        message.role = reader.readInt32();
                        break;
                    case 4:
                        message.sex = reader.readInt32();
                        break;
                    case 5:
                        message.device_id = reader.readString();
                        break;
                    case 6:
                        message.phone_number = reader.readString();
                        break;
                    case 7:
                        message.real_name = reader.readString();
                        break;
                    case 8:
                        message.wx_open_id = reader.readString();
                        break;
                    case 9:
                        message.head_img_url = reader.readString();
                        break;
                    case 10:
                        message.nick_name = reader.readString();
                        break;
                    case 11:
                        message.privilege_data = reader.readBool();
                        break;
                    case 12:
                        message.arrears = reader.readDouble();
                        break;
                    case 13:
                        message.delete_at = reader.readInt64();
                        break;
                    case 14:
                        message.name_key = reader.readString();
                        break;
                    case 15:
                        message.last_buy_time = reader.readInt64();
                        break;
                    case 16:
                        message.update_at = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncUser {
            return SyncUser.deserialize(bytes);
        }
    }
    export class SyncCommodities extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncCommodity[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncCommodity, 1) as SyncCommodity[];
        }
        set list(value: SyncCommodity[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncCommodity.prototype.toObject>[];
        }) {
            const message = new SyncCommodities({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncCommodity.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncCommodity.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncCommodity) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncCommodity) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncCommodities {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncCommodities();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncCommodity.deserialize(reader), SyncCommodity));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncCommodities {
            return SyncCommodities.deserialize(bytes);
        }
    }
    export class SyncCommodity extends pb_1.Message {
        constructor(data?: any[] | {
            snid?: number;
            sid?: number;
            sell_type_id?: number;
            commodity?: string;
            unit_price?: number;
            frequency?: number;
            volume?: number;
            discount?: number;
            months?: number;
            dc_pay_range?: string;
            delete_at?: number;
            name_key?: string;
            update_at?: number;
            gift_amount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("snid" in data && data.snid != undefined) {
                    this.snid = data.snid;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("sell_type_id" in data && data.sell_type_id != undefined) {
                    this.sell_type_id = data.sell_type_id;
                }
                if ("commodity" in data && data.commodity != undefined) {
                    this.commodity = data.commodity;
                }
                if ("unit_price" in data && data.unit_price != undefined) {
                    this.unit_price = data.unit_price;
                }
                if ("frequency" in data && data.frequency != undefined) {
                    this.frequency = data.frequency;
                }
                if ("volume" in data && data.volume != undefined) {
                    this.volume = data.volume;
                }
                if ("discount" in data && data.discount != undefined) {
                    this.discount = data.discount;
                }
                if ("months" in data && data.months != undefined) {
                    this.months = data.months;
                }
                if ("dc_pay_range" in data && data.dc_pay_range != undefined) {
                    this.dc_pay_range = data.dc_pay_range;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("name_key" in data && data.name_key != undefined) {
                    this.name_key = data.name_key;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
                if ("gift_amount" in data && data.gift_amount != undefined) {
                    this.gift_amount = data.gift_amount;
                }
            }
        }
        get snid() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set snid(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 2) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get sell_type_id() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set sell_type_id(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get commodity() {
            return pb_1.Message.getField(this, 4) as string;
        }
        set commodity(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get unit_price() {
            return pb_1.Message.getField(this, 5) as number;
        }
        set unit_price(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get frequency() {
            return pb_1.Message.getField(this, 6) as number;
        }
        set frequency(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get volume() {
            return pb_1.Message.getField(this, 7) as number;
        }
        set volume(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get discount() {
            return pb_1.Message.getField(this, 8) as number;
        }
        set discount(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get months() {
            return pb_1.Message.getField(this, 9) as number;
        }
        set months(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get dc_pay_range() {
            return pb_1.Message.getField(this, 10) as string;
        }
        set dc_pay_range(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 11) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get name_key() {
            return pb_1.Message.getField(this, 12) as string;
        }
        set name_key(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 13) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get gift_amount() {
            return pb_1.Message.getField(this, 14) as number;
        }
        set gift_amount(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        static fromObject(data: {
            snid?: number;
            sid?: number;
            sell_type_id?: number;
            commodity?: string;
            unit_price?: number;
            frequency?: number;
            volume?: number;
            discount?: number;
            months?: number;
            dc_pay_range?: string;
            delete_at?: number;
            name_key?: string;
            update_at?: number;
            gift_amount?: number;
        }) {
            const message = new SyncCommodity({});
            if (data.snid != null) {
                message.snid = data.snid;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.sell_type_id != null) {
                message.sell_type_id = data.sell_type_id;
            }
            if (data.commodity != null) {
                message.commodity = data.commodity;
            }
            if (data.unit_price != null) {
                message.unit_price = data.unit_price;
            }
            if (data.frequency != null) {
                message.frequency = data.frequency;
            }
            if (data.volume != null) {
                message.volume = data.volume;
            }
            if (data.discount != null) {
                message.discount = data.discount;
            }
            if (data.months != null) {
                message.months = data.months;
            }
            if (data.dc_pay_range != null) {
                message.dc_pay_range = data.dc_pay_range;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.name_key != null) {
                message.name_key = data.name_key;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            if (data.gift_amount != null) {
                message.gift_amount = data.gift_amount;
            }
            return message;
        }
        toObject() {
            const data: {
                snid?: number;
                sid?: number;
                sell_type_id?: number;
                commodity?: string;
                unit_price?: number;
                frequency?: number;
                volume?: number;
                discount?: number;
                months?: number;
                dc_pay_range?: string;
                delete_at?: number;
                name_key?: string;
                update_at?: number;
                gift_amount?: number;
            } = {};
            if (this.snid != null) {
                data.snid = this.snid;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.sell_type_id != null) {
                data.sell_type_id = this.sell_type_id;
            }
            if (this.commodity != null) {
                data.commodity = this.commodity;
            }
            if (this.unit_price != null) {
                data.unit_price = this.unit_price;
            }
            if (this.frequency != null) {
                data.frequency = this.frequency;
            }
            if (this.volume != null) {
                data.volume = this.volume;
            }
            if (this.discount != null) {
                data.discount = this.discount;
            }
            if (this.months != null) {
                data.months = this.months;
            }
            if (this.dc_pay_range != null) {
                data.dc_pay_range = this.dc_pay_range;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.name_key != null) {
                data.name_key = this.name_key;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            if (this.gift_amount != null) {
                data.gift_amount = this.gift_amount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.snid !== undefined)
                writer.writeInt64(1, this.snid);
            if (this.sid !== undefined)
                writer.writeInt64(2, this.sid);
            if (this.sell_type_id !== undefined)
                writer.writeInt32(3, this.sell_type_id);
            if (typeof this.commodity === "string" && this.commodity.length)
                writer.writeString(4, this.commodity);
            if (this.unit_price !== undefined)
                writer.writeDouble(5, this.unit_price);
            if (this.frequency !== undefined)
                writer.writeInt32(6, this.frequency);
            if (this.volume !== undefined)
                writer.writeInt32(7, this.volume);
            if (this.discount !== undefined)
                writer.writeFloat(8, this.discount);
            if (this.months !== undefined)
                writer.writeInt32(9, this.months);
            if (typeof this.dc_pay_range === "string" && this.dc_pay_range.length)
                writer.writeString(10, this.dc_pay_range);
            if (this.delete_at !== undefined)
                writer.writeInt64(11, this.delete_at);
            if (typeof this.name_key === "string" && this.name_key.length)
                writer.writeString(12, this.name_key);
            if (this.update_at !== undefined)
                writer.writeInt64(13, this.update_at);
            if (this.gift_amount !== undefined)
                writer.writeDouble(14, this.gift_amount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncCommodity {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncCommodity();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.snid = reader.readInt64();
                        break;
                    case 2:
                        message.sid = reader.readInt64();
                        break;
                    case 3:
                        message.sell_type_id = reader.readInt32();
                        break;
                    case 4:
                        message.commodity = reader.readString();
                        break;
                    case 5:
                        message.unit_price = reader.readDouble();
                        break;
                    case 6:
                        message.frequency = reader.readInt32();
                        break;
                    case 7:
                        message.volume = reader.readInt32();
                        break;
                    case 8:
                        message.discount = reader.readFloat();
                        break;
                    case 9:
                        message.months = reader.readInt32();
                        break;
                    case 10:
                        message.dc_pay_range = reader.readString();
                        break;
                    case 11:
                        message.delete_at = reader.readInt64();
                        break;
                    case 12:
                        message.name_key = reader.readString();
                        break;
                    case 13:
                        message.update_at = reader.readInt64();
                        break;
                    case 14:
                        message.gift_amount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncCommodity {
            return SyncCommodity.deserialize(bytes);
        }
    }
    export class SyncMemberPockets extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncMemberPocket[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncMemberPocket, 1) as SyncMemberPocket[];
        }
        set list(value: SyncMemberPocket[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncMemberPocket.prototype.toObject>[];
        }) {
            const message = new SyncMemberPockets({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncMemberPocket.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncMemberPocket.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncMemberPocket) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncMemberPocket) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncMemberPockets {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncMemberPockets();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncMemberPocket.deserialize(reader), SyncMemberPocket));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncMemberPockets {
            return SyncMemberPockets.deserialize(bytes);
        }
    }
    export class SyncMemberPocket extends pb_1.Message {
        constructor(data?: any[] | {
            mpid?: number;
            sid?: number;
            uid?: number;
            sell_type_id?: number;
            card_name?: string;
            frequency?: number;
            frequency_balance?: number;
            unit_price?: number;
            money_balance?: number;
            bid?: number;
            expire_at?: number;
            integral?: number;
            dc_pay_range?: string;
            delete_at?: number;
            update_at?: number;
            buy_types_limit?: number[];
            use_percent?: number;
            creator_uid?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [17], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("mpid" in data && data.mpid != undefined) {
                    this.mpid = data.mpid;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("uid" in data && data.uid != undefined) {
                    this.uid = data.uid;
                }
                if ("sell_type_id" in data && data.sell_type_id != undefined) {
                    this.sell_type_id = data.sell_type_id;
                }
                if ("card_name" in data && data.card_name != undefined) {
                    this.card_name = data.card_name;
                }
                if ("frequency" in data && data.frequency != undefined) {
                    this.frequency = data.frequency;
                }
                if ("frequency_balance" in data && data.frequency_balance != undefined) {
                    this.frequency_balance = data.frequency_balance;
                }
                if ("unit_price" in data && data.unit_price != undefined) {
                    this.unit_price = data.unit_price;
                }
                if ("money_balance" in data && data.money_balance != undefined) {
                    this.money_balance = data.money_balance;
                }
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
                if ("expire_at" in data && data.expire_at != undefined) {
                    this.expire_at = data.expire_at;
                }
                if ("integral" in data && data.integral != undefined) {
                    this.integral = data.integral;
                }
                if ("dc_pay_range" in data && data.dc_pay_range != undefined) {
                    this.dc_pay_range = data.dc_pay_range;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
                if ("buy_types_limit" in data && data.buy_types_limit != undefined) {
                    this.buy_types_limit = data.buy_types_limit;
                }
                if ("use_percent" in data && data.use_percent != undefined) {
                    this.use_percent = data.use_percent;
                }
                if ("creator_uid" in data && data.creator_uid != undefined) {
                    this.creator_uid = data.creator_uid;
                }
            }
        }
        get mpid() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set mpid(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 2) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get uid() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set uid(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get sell_type_id() {
            return pb_1.Message.getField(this, 4) as number;
        }
        set sell_type_id(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get card_name() {
            return pb_1.Message.getField(this, 5) as string;
        }
        set card_name(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get frequency() {
            return pb_1.Message.getField(this, 6) as number;
        }
        set frequency(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get frequency_balance() {
            return pb_1.Message.getField(this, 7) as number;
        }
        set frequency_balance(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get unit_price() {
            return pb_1.Message.getField(this, 8) as number;
        }
        set unit_price(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get money_balance() {
            return pb_1.Message.getField(this, 9) as number;
        }
        set money_balance(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get bid() {
            return pb_1.Message.getField(this, 10) as number;
        }
        set bid(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get expire_at() {
            return pb_1.Message.getField(this, 12) as number;
        }
        set expire_at(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        get integral() {
            return pb_1.Message.getField(this, 13) as number;
        }
        set integral(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get dc_pay_range() {
            return pb_1.Message.getField(this, 14) as string;
        }
        set dc_pay_range(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 15) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 16) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        get buy_types_limit() {
            return pb_1.Message.getField(this, 17) as number[];
        }
        set buy_types_limit(value: number[]) {
            pb_1.Message.setField(this, 17, value);
        }
        get use_percent() {
            return pb_1.Message.getField(this, 18) as number;
        }
        set use_percent(value: number) {
            pb_1.Message.setField(this, 18, value);
        }
        get creator_uid() {
            return pb_1.Message.getField(this, 19) as number;
        }
        set creator_uid(value: number) {
            pb_1.Message.setField(this, 19, value);
        }
        static fromObject(data: {
            mpid?: number;
            sid?: number;
            uid?: number;
            sell_type_id?: number;
            card_name?: string;
            frequency?: number;
            frequency_balance?: number;
            unit_price?: number;
            money_balance?: number;
            bid?: number;
            expire_at?: number;
            integral?: number;
            dc_pay_range?: string;
            delete_at?: number;
            update_at?: number;
            buy_types_limit?: number[];
            use_percent?: number;
            creator_uid?: number;
        }) {
            const message = new SyncMemberPocket({});
            if (data.mpid != null) {
                message.mpid = data.mpid;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.uid != null) {
                message.uid = data.uid;
            }
            if (data.sell_type_id != null) {
                message.sell_type_id = data.sell_type_id;
            }
            if (data.card_name != null) {
                message.card_name = data.card_name;
            }
            if (data.frequency != null) {
                message.frequency = data.frequency;
            }
            if (data.frequency_balance != null) {
                message.frequency_balance = data.frequency_balance;
            }
            if (data.unit_price != null) {
                message.unit_price = data.unit_price;
            }
            if (data.money_balance != null) {
                message.money_balance = data.money_balance;
            }
            if (data.bid != null) {
                message.bid = data.bid;
            }
            if (data.expire_at != null) {
                message.expire_at = data.expire_at;
            }
            if (data.integral != null) {
                message.integral = data.integral;
            }
            if (data.dc_pay_range != null) {
                message.dc_pay_range = data.dc_pay_range;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            if (data.buy_types_limit != null) {
                message.buy_types_limit = data.buy_types_limit;
            }
            if (data.use_percent != null) {
                message.use_percent = data.use_percent;
            }
            if (data.creator_uid != null) {
                message.creator_uid = data.creator_uid;
            }
            return message;
        }
        toObject() {
            const data: {
                mpid?: number;
                sid?: number;
                uid?: number;
                sell_type_id?: number;
                card_name?: string;
                frequency?: number;
                frequency_balance?: number;
                unit_price?: number;
                money_balance?: number;
                bid?: number;
                expire_at?: number;
                integral?: number;
                dc_pay_range?: string;
                delete_at?: number;
                update_at?: number;
                buy_types_limit?: number[];
                use_percent?: number;
                creator_uid?: number;
            } = {};
            if (this.mpid != null) {
                data.mpid = this.mpid;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.uid != null) {
                data.uid = this.uid;
            }
            if (this.sell_type_id != null) {
                data.sell_type_id = this.sell_type_id;
            }
            if (this.card_name != null) {
                data.card_name = this.card_name;
            }
            if (this.frequency != null) {
                data.frequency = this.frequency;
            }
            if (this.frequency_balance != null) {
                data.frequency_balance = this.frequency_balance;
            }
            if (this.unit_price != null) {
                data.unit_price = this.unit_price;
            }
            if (this.money_balance != null) {
                data.money_balance = this.money_balance;
            }
            if (this.bid != null) {
                data.bid = this.bid;
            }
            if (this.expire_at != null) {
                data.expire_at = this.expire_at;
            }
            if (this.integral != null) {
                data.integral = this.integral;
            }
            if (this.dc_pay_range != null) {
                data.dc_pay_range = this.dc_pay_range;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            if (this.buy_types_limit != null) {
                data.buy_types_limit = this.buy_types_limit;
            }
            if (this.use_percent != null) {
                data.use_percent = this.use_percent;
            }
            if (this.creator_uid != null) {
                data.creator_uid = this.creator_uid;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.mpid !== undefined)
                writer.writeInt64(1, this.mpid);
            if (this.sid !== undefined)
                writer.writeInt64(2, this.sid);
            if (this.uid !== undefined)
                writer.writeInt64(3, this.uid);
            if (this.sell_type_id !== undefined)
                writer.writeInt32(4, this.sell_type_id);
            if (typeof this.card_name === "string" && this.card_name.length)
                writer.writeString(5, this.card_name);
            if (this.frequency !== undefined)
                writer.writeInt32(6, this.frequency);
            if (this.frequency_balance !== undefined)
                writer.writeInt32(7, this.frequency_balance);
            if (this.unit_price !== undefined)
                writer.writeDouble(8, this.unit_price);
            if (this.money_balance !== undefined)
                writer.writeDouble(9, this.money_balance);
            if (this.bid !== undefined)
                writer.writeInt64(10, this.bid);
            if (this.expire_at !== undefined)
                writer.writeInt64(12, this.expire_at);
            if (this.integral !== undefined)
                writer.writeInt32(13, this.integral);
            if (typeof this.dc_pay_range === "string" && this.dc_pay_range.length)
                writer.writeString(14, this.dc_pay_range);
            if (this.delete_at !== undefined)
                writer.writeInt64(15, this.delete_at);
            if (this.update_at !== undefined)
                writer.writeInt64(16, this.update_at);
            if (this.buy_types_limit !== undefined)
                writer.writePackedInt64(17, this.buy_types_limit);
            if (this.use_percent !== undefined)
                writer.writeInt32(18, this.use_percent);
            if (this.creator_uid !== undefined)
                writer.writeInt64(19, this.creator_uid);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncMemberPocket {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncMemberPocket();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.mpid = reader.readInt64();
                        break;
                    case 2:
                        message.sid = reader.readInt64();
                        break;
                    case 3:
                        message.uid = reader.readInt64();
                        break;
                    case 4:
                        message.sell_type_id = reader.readInt32();
                        break;
                    case 5:
                        message.card_name = reader.readString();
                        break;
                    case 6:
                        message.frequency = reader.readInt32();
                        break;
                    case 7:
                        message.frequency_balance = reader.readInt32();
                        break;
                    case 8:
                        message.unit_price = reader.readDouble();
                        break;
                    case 9:
                        message.money_balance = reader.readDouble();
                        break;
                    case 10:
                        message.bid = reader.readInt64();
                        break;
                    case 12:
                        message.expire_at = reader.readInt64();
                        break;
                    case 13:
                        message.integral = reader.readInt32();
                        break;
                    case 14:
                        message.dc_pay_range = reader.readString();
                        break;
                    case 15:
                        message.delete_at = reader.readInt64();
                        break;
                    case 16:
                        message.update_at = reader.readInt64();
                        break;
                    case 17:
                        message.buy_types_limit = reader.readPackedInt64();
                        break;
                    case 18:
                        message.use_percent = reader.readInt32();
                        break;
                    case 19:
                        message.creator_uid = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncMemberPocket {
            return SyncMemberPocket.deserialize(bytes);
        }
    }
    export class SyncBills extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncBill[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncBill, 1) as SyncBill[];
        }
        set list(value: SyncBill[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncBill.prototype.toObject>[];
        }) {
            const message = new SyncBills({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncBill.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncBill.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncBill) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncBill) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncBills {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncBills();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncBill.deserialize(reader), SyncBill));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncBills {
            return SyncBills.deserialize(bytes);
        }
    }
    export class SyncBill extends pb_1.Message {
        constructor(data?: any[] | {
            bid?: number;
            open_bid?: string;
            sid?: number;
            creator_uid?: number;
            buyer_uid?: number;
            pay_fees?: number;
            sell_type_id?: number;
            member_card_id?: number;
            is_set?: boolean;
            arrears?: number;
            repayment_bid?: number;
            mark?: string;
            create_at?: number;
            delete_at?: number;
            update_at?: number;
            integral?: number;
            is_female?: boolean;
            attach_pic?: string;
            member_card_fees?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
                if ("open_bid" in data && data.open_bid != undefined) {
                    this.open_bid = data.open_bid;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("creator_uid" in data && data.creator_uid != undefined) {
                    this.creator_uid = data.creator_uid;
                }
                if ("buyer_uid" in data && data.buyer_uid != undefined) {
                    this.buyer_uid = data.buyer_uid;
                }
                if ("pay_fees" in data && data.pay_fees != undefined) {
                    this.pay_fees = data.pay_fees;
                }
                if ("sell_type_id" in data && data.sell_type_id != undefined) {
                    this.sell_type_id = data.sell_type_id;
                }
                if ("member_card_id" in data && data.member_card_id != undefined) {
                    this.member_card_id = data.member_card_id;
                }
                if ("is_set" in data && data.is_set != undefined) {
                    this.is_set = data.is_set;
                }
                if ("arrears" in data && data.arrears != undefined) {
                    this.arrears = data.arrears;
                }
                if ("repayment_bid" in data && data.repayment_bid != undefined) {
                    this.repayment_bid = data.repayment_bid;
                }
                if ("mark" in data && data.mark != undefined) {
                    this.mark = data.mark;
                }
                if ("create_at" in data && data.create_at != undefined) {
                    this.create_at = data.create_at;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
                if ("integral" in data && data.integral != undefined) {
                    this.integral = data.integral;
                }
                if ("is_female" in data && data.is_female != undefined) {
                    this.is_female = data.is_female;
                }
                if ("attach_pic" in data && data.attach_pic != undefined) {
                    this.attach_pic = data.attach_pic;
                }
                if ("member_card_fees" in data && data.member_card_fees != undefined) {
                    this.member_card_fees = data.member_card_fees;
                }
            }
        }
        get bid() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set bid(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get open_bid() {
            return pb_1.Message.getField(this, 2) as string;
        }
        set open_bid(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get creator_uid() {
            return pb_1.Message.getField(this, 4) as number;
        }
        set creator_uid(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get buyer_uid() {
            return pb_1.Message.getField(this, 5) as number;
        }
        set buyer_uid(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get pay_fees() {
            return pb_1.Message.getField(this, 6) as number;
        }
        set pay_fees(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get sell_type_id() {
            return pb_1.Message.getField(this, 7) as number;
        }
        set sell_type_id(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get member_card_id() {
            return pb_1.Message.getField(this, 8) as number;
        }
        set member_card_id(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get is_set() {
            return pb_1.Message.getField(this, 9) as boolean;
        }
        set is_set(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        get arrears() {
            return pb_1.Message.getField(this, 10) as number;
        }
        set arrears(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get repayment_bid() {
            return pb_1.Message.getField(this, 11) as number;
        }
        set repayment_bid(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get mark() {
            return pb_1.Message.getField(this, 12) as string;
        }
        set mark(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get create_at() {
            return pb_1.Message.getField(this, 13) as number;
        }
        set create_at(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 14) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 15) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get integral() {
            return pb_1.Message.getField(this, 16) as number;
        }
        set integral(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        get is_female() {
            return pb_1.Message.getField(this, 17) as boolean;
        }
        set is_female(value: boolean) {
            pb_1.Message.setField(this, 17, value);
        }
        get attach_pic() {
            return pb_1.Message.getField(this, 18) as string;
        }
        set attach_pic(value: string) {
            pb_1.Message.setField(this, 18, value);
        }
        get member_card_fees() {
            return pb_1.Message.getField(this, 19) as number;
        }
        set member_card_fees(value: number) {
            pb_1.Message.setField(this, 19, value);
        }
        static fromObject(data: {
            bid?: number;
            open_bid?: string;
            sid?: number;
            creator_uid?: number;
            buyer_uid?: number;
            pay_fees?: number;
            sell_type_id?: number;
            member_card_id?: number;
            is_set?: boolean;
            arrears?: number;
            repayment_bid?: number;
            mark?: string;
            create_at?: number;
            delete_at?: number;
            update_at?: number;
            integral?: number;
            is_female?: boolean;
            attach_pic?: string;
            member_card_fees?: number;
        }) {
            const message = new SyncBill({});
            if (data.bid != null) {
                message.bid = data.bid;
            }
            if (data.open_bid != null) {
                message.open_bid = data.open_bid;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.creator_uid != null) {
                message.creator_uid = data.creator_uid;
            }
            if (data.buyer_uid != null) {
                message.buyer_uid = data.buyer_uid;
            }
            if (data.pay_fees != null) {
                message.pay_fees = data.pay_fees;
            }
            if (data.sell_type_id != null) {
                message.sell_type_id = data.sell_type_id;
            }
            if (data.member_card_id != null) {
                message.member_card_id = data.member_card_id;
            }
            if (data.is_set != null) {
                message.is_set = data.is_set;
            }
            if (data.arrears != null) {
                message.arrears = data.arrears;
            }
            if (data.repayment_bid != null) {
                message.repayment_bid = data.repayment_bid;
            }
            if (data.mark != null) {
                message.mark = data.mark;
            }
            if (data.create_at != null) {
                message.create_at = data.create_at;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            if (data.integral != null) {
                message.integral = data.integral;
            }
            if (data.is_female != null) {
                message.is_female = data.is_female;
            }
            if (data.attach_pic != null) {
                message.attach_pic = data.attach_pic;
            }
            if (data.member_card_fees != null) {
                message.member_card_fees = data.member_card_fees;
            }
            return message;
        }
        toObject() {
            const data: {
                bid?: number;
                open_bid?: string;
                sid?: number;
                creator_uid?: number;
                buyer_uid?: number;
                pay_fees?: number;
                sell_type_id?: number;
                member_card_id?: number;
                is_set?: boolean;
                arrears?: number;
                repayment_bid?: number;
                mark?: string;
                create_at?: number;
                delete_at?: number;
                update_at?: number;
                integral?: number;
                is_female?: boolean;
                attach_pic?: string;
                member_card_fees?: number;
            } = {};
            if (this.bid != null) {
                data.bid = this.bid;
            }
            if (this.open_bid != null) {
                data.open_bid = this.open_bid;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.creator_uid != null) {
                data.creator_uid = this.creator_uid;
            }
            if (this.buyer_uid != null) {
                data.buyer_uid = this.buyer_uid;
            }
            if (this.pay_fees != null) {
                data.pay_fees = this.pay_fees;
            }
            if (this.sell_type_id != null) {
                data.sell_type_id = this.sell_type_id;
            }
            if (this.member_card_id != null) {
                data.member_card_id = this.member_card_id;
            }
            if (this.is_set != null) {
                data.is_set = this.is_set;
            }
            if (this.arrears != null) {
                data.arrears = this.arrears;
            }
            if (this.repayment_bid != null) {
                data.repayment_bid = this.repayment_bid;
            }
            if (this.mark != null) {
                data.mark = this.mark;
            }
            if (this.create_at != null) {
                data.create_at = this.create_at;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            if (this.integral != null) {
                data.integral = this.integral;
            }
            if (this.is_female != null) {
                data.is_female = this.is_female;
            }
            if (this.attach_pic != null) {
                data.attach_pic = this.attach_pic;
            }
            if (this.member_card_fees != null) {
                data.member_card_fees = this.member_card_fees;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.bid !== undefined)
                writer.writeInt64(1, this.bid);
            if (typeof this.open_bid === "string" && this.open_bid.length)
                writer.writeString(2, this.open_bid);
            if (this.sid !== undefined)
                writer.writeInt64(3, this.sid);
            if (this.creator_uid !== undefined)
                writer.writeInt64(4, this.creator_uid);
            if (this.buyer_uid !== undefined)
                writer.writeInt64(5, this.buyer_uid);
            if (this.pay_fees !== undefined)
                writer.writeDouble(6, this.pay_fees);
            if (this.sell_type_id !== undefined)
                writer.writeInt32(7, this.sell_type_id);
            if (this.member_card_id !== undefined)
                writer.writeInt64(8, this.member_card_id);
            if (this.is_set !== undefined)
                writer.writeBool(9, this.is_set);
            if (this.arrears !== undefined)
                writer.writeDouble(10, this.arrears);
            if (this.repayment_bid !== undefined)
                writer.writeInt64(11, this.repayment_bid);
            if (typeof this.mark === "string" && this.mark.length)
                writer.writeString(12, this.mark);
            if (this.create_at !== undefined)
                writer.writeInt64(13, this.create_at);
            if (this.delete_at !== undefined)
                writer.writeInt64(14, this.delete_at);
            if (this.update_at !== undefined)
                writer.writeInt64(15, this.update_at);
            if (this.integral !== undefined)
                writer.writeInt64(16, this.integral);
            if (this.is_female !== undefined)
                writer.writeBool(17, this.is_female);
            if (typeof this.attach_pic === "string" && this.attach_pic.length)
                writer.writeString(18, this.attach_pic);
            if (this.member_card_fees !== undefined)
                writer.writeDouble(19, this.member_card_fees);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncBill {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncBill();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.bid = reader.readInt64();
                        break;
                    case 2:
                        message.open_bid = reader.readString();
                        break;
                    case 3:
                        message.sid = reader.readInt64();
                        break;
                    case 4:
                        message.creator_uid = reader.readInt64();
                        break;
                    case 5:
                        message.buyer_uid = reader.readInt64();
                        break;
                    case 6:
                        message.pay_fees = reader.readDouble();
                        break;
                    case 7:
                        message.sell_type_id = reader.readInt32();
                        break;
                    case 8:
                        message.member_card_id = reader.readInt64();
                        break;
                    case 9:
                        message.is_set = reader.readBool();
                        break;
                    case 10:
                        message.arrears = reader.readDouble();
                        break;
                    case 11:
                        message.repayment_bid = reader.readInt64();
                        break;
                    case 12:
                        message.mark = reader.readString();
                        break;
                    case 13:
                        message.create_at = reader.readInt64();
                        break;
                    case 14:
                        message.delete_at = reader.readInt64();
                        break;
                    case 15:
                        message.update_at = reader.readInt64();
                        break;
                    case 16:
                        message.integral = reader.readInt64();
                        break;
                    case 17:
                        message.is_female = reader.readBool();
                        break;
                    case 18:
                        message.attach_pic = reader.readString();
                        break;
                    case 19:
                        message.member_card_fees = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncBill {
            return SyncBill.deserialize(bytes);
        }
    }
    export class SyncBillPerformances extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncBillPerformance[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncBillPerformance, 1) as SyncBillPerformance[];
        }
        set list(value: SyncBillPerformance[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncBillPerformance.prototype.toObject>[];
        }) {
            const message = new SyncBillPerformances({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncBillPerformance.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncBillPerformance.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncBillPerformance) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncBillPerformance) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncBillPerformances {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncBillPerformances();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncBillPerformance.deserialize(reader), SyncBillPerformance));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncBillPerformances {
            return SyncBillPerformances.deserialize(bytes);
        }
    }
    export class SyncBillPerformance extends pb_1.Message {
        constructor(data?: any[] | {
            bpid?: number;
            bid?: number;
            sid?: number;
            employee_uid?: number;
            performance?: number;
            commission?: number;
            union_id?: string;
            real_name?: string;
            is_appoint?: boolean;
            buyer_uid?: number;
            member_card_id?: number;
            pay_fees?: number;
            note?: string;
            create_at?: number;
            delete_at?: number;
            update_at?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bpid" in data && data.bpid != undefined) {
                    this.bpid = data.bpid;
                }
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("employee_uid" in data && data.employee_uid != undefined) {
                    this.employee_uid = data.employee_uid;
                }
                if ("performance" in data && data.performance != undefined) {
                    this.performance = data.performance;
                }
                if ("commission" in data && data.commission != undefined) {
                    this.commission = data.commission;
                }
                if ("union_id" in data && data.union_id != undefined) {
                    this.union_id = data.union_id;
                }
                if ("real_name" in data && data.real_name != undefined) {
                    this.real_name = data.real_name;
                }
                if ("is_appoint" in data && data.is_appoint != undefined) {
                    this.is_appoint = data.is_appoint;
                }
                if ("buyer_uid" in data && data.buyer_uid != undefined) {
                    this.buyer_uid = data.buyer_uid;
                }
                if ("member_card_id" in data && data.member_card_id != undefined) {
                    this.member_card_id = data.member_card_id;
                }
                if ("pay_fees" in data && data.pay_fees != undefined) {
                    this.pay_fees = data.pay_fees;
                }
                if ("note" in data && data.note != undefined) {
                    this.note = data.note;
                }
                if ("create_at" in data && data.create_at != undefined) {
                    this.create_at = data.create_at;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
            }
        }
        get bpid() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set bpid(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get bid() {
            return pb_1.Message.getField(this, 2) as number;
        }
        set bid(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get employee_uid() {
            return pb_1.Message.getField(this, 4) as number;
        }
        set employee_uid(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get performance() {
            return pb_1.Message.getField(this, 5) as number;
        }
        set performance(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get commission() {
            return pb_1.Message.getField(this, 6) as number;
        }
        set commission(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get union_id() {
            return pb_1.Message.getField(this, 7) as string;
        }
        set union_id(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get real_name() {
            return pb_1.Message.getField(this, 8) as string;
        }
        set real_name(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get is_appoint() {
            return pb_1.Message.getField(this, 9) as boolean;
        }
        set is_appoint(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        get buyer_uid() {
            return pb_1.Message.getField(this, 10) as number;
        }
        set buyer_uid(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get member_card_id() {
            return pb_1.Message.getField(this, 11) as number;
        }
        set member_card_id(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get pay_fees() {
            return pb_1.Message.getField(this, 12) as number;
        }
        set pay_fees(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        get note() {
            return pb_1.Message.getField(this, 13) as string;
        }
        set note(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get create_at() {
            return pb_1.Message.getField(this, 14) as number;
        }
        set create_at(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 15) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 16) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        static fromObject(data: {
            bpid?: number;
            bid?: number;
            sid?: number;
            employee_uid?: number;
            performance?: number;
            commission?: number;
            union_id?: string;
            real_name?: string;
            is_appoint?: boolean;
            buyer_uid?: number;
            member_card_id?: number;
            pay_fees?: number;
            note?: string;
            create_at?: number;
            delete_at?: number;
            update_at?: number;
        }) {
            const message = new SyncBillPerformance({});
            if (data.bpid != null) {
                message.bpid = data.bpid;
            }
            if (data.bid != null) {
                message.bid = data.bid;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.employee_uid != null) {
                message.employee_uid = data.employee_uid;
            }
            if (data.performance != null) {
                message.performance = data.performance;
            }
            if (data.commission != null) {
                message.commission = data.commission;
            }
            if (data.union_id != null) {
                message.union_id = data.union_id;
            }
            if (data.real_name != null) {
                message.real_name = data.real_name;
            }
            if (data.is_appoint != null) {
                message.is_appoint = data.is_appoint;
            }
            if (data.buyer_uid != null) {
                message.buyer_uid = data.buyer_uid;
            }
            if (data.member_card_id != null) {
                message.member_card_id = data.member_card_id;
            }
            if (data.pay_fees != null) {
                message.pay_fees = data.pay_fees;
            }
            if (data.note != null) {
                message.note = data.note;
            }
            if (data.create_at != null) {
                message.create_at = data.create_at;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            return message;
        }
        toObject() {
            const data: {
                bpid?: number;
                bid?: number;
                sid?: number;
                employee_uid?: number;
                performance?: number;
                commission?: number;
                union_id?: string;
                real_name?: string;
                is_appoint?: boolean;
                buyer_uid?: number;
                member_card_id?: number;
                pay_fees?: number;
                note?: string;
                create_at?: number;
                delete_at?: number;
                update_at?: number;
            } = {};
            if (this.bpid != null) {
                data.bpid = this.bpid;
            }
            if (this.bid != null) {
                data.bid = this.bid;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.employee_uid != null) {
                data.employee_uid = this.employee_uid;
            }
            if (this.performance != null) {
                data.performance = this.performance;
            }
            if (this.commission != null) {
                data.commission = this.commission;
            }
            if (this.union_id != null) {
                data.union_id = this.union_id;
            }
            if (this.real_name != null) {
                data.real_name = this.real_name;
            }
            if (this.is_appoint != null) {
                data.is_appoint = this.is_appoint;
            }
            if (this.buyer_uid != null) {
                data.buyer_uid = this.buyer_uid;
            }
            if (this.member_card_id != null) {
                data.member_card_id = this.member_card_id;
            }
            if (this.pay_fees != null) {
                data.pay_fees = this.pay_fees;
            }
            if (this.note != null) {
                data.note = this.note;
            }
            if (this.create_at != null) {
                data.create_at = this.create_at;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.bpid !== undefined)
                writer.writeInt64(1, this.bpid);
            if (this.bid !== undefined)
                writer.writeInt64(2, this.bid);
            if (this.sid !== undefined)
                writer.writeInt64(3, this.sid);
            if (this.employee_uid !== undefined)
                writer.writeInt64(4, this.employee_uid);
            if (this.performance !== undefined)
                writer.writeDouble(5, this.performance);
            if (this.commission !== undefined)
                writer.writeDouble(6, this.commission);
            if (typeof this.union_id === "string" && this.union_id.length)
                writer.writeString(7, this.union_id);
            if (typeof this.real_name === "string" && this.real_name.length)
                writer.writeString(8, this.real_name);
            if (this.is_appoint !== undefined)
                writer.writeBool(9, this.is_appoint);
            if (this.buyer_uid !== undefined)
                writer.writeInt64(10, this.buyer_uid);
            if (this.member_card_id !== undefined)
                writer.writeInt64(11, this.member_card_id);
            if (this.pay_fees !== undefined)
                writer.writeDouble(12, this.pay_fees);
            if (typeof this.note === "string" && this.note.length)
                writer.writeString(13, this.note);
            if (this.create_at !== undefined)
                writer.writeInt64(14, this.create_at);
            if (this.delete_at !== undefined)
                writer.writeInt64(15, this.delete_at);
            if (this.update_at !== undefined)
                writer.writeInt64(16, this.update_at);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncBillPerformance {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncBillPerformance();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.bpid = reader.readInt64();
                        break;
                    case 2:
                        message.bid = reader.readInt64();
                        break;
                    case 3:
                        message.sid = reader.readInt64();
                        break;
                    case 4:
                        message.employee_uid = reader.readInt64();
                        break;
                    case 5:
                        message.performance = reader.readDouble();
                        break;
                    case 6:
                        message.commission = reader.readDouble();
                        break;
                    case 7:
                        message.union_id = reader.readString();
                        break;
                    case 8:
                        message.real_name = reader.readString();
                        break;
                    case 9:
                        message.is_appoint = reader.readBool();
                        break;
                    case 10:
                        message.buyer_uid = reader.readInt64();
                        break;
                    case 11:
                        message.member_card_id = reader.readInt64();
                        break;
                    case 12:
                        message.pay_fees = reader.readDouble();
                        break;
                    case 13:
                        message.note = reader.readString();
                        break;
                    case 14:
                        message.create_at = reader.readInt64();
                        break;
                    case 15:
                        message.delete_at = reader.readInt64();
                        break;
                    case 16:
                        message.update_at = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncBillPerformance {
            return SyncBillPerformance.deserialize(bytes);
        }
    }
    export class SyncMemberVouchers extends pb_1.Message {
        constructor(data?: any[] | {
            list?: SyncMemberVoucher[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("list" in data && data.list != undefined) {
                    this.list = data.list;
                }
            }
        }
        get list() {
            return pb_1.Message.getRepeatedWrapperField(this, SyncMemberVoucher, 1) as SyncMemberVoucher[];
        }
        set list(value: SyncMemberVoucher[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            list?: ReturnType<typeof SyncMemberVoucher.prototype.toObject>[];
        }) {
            const message = new SyncMemberVouchers({});
            if (data.list != null) {
                message.list = data.list.map(item => SyncMemberVoucher.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                list?: ReturnType<typeof SyncMemberVoucher.prototype.toObject>[];
            } = {};
            if (this.list != null) {
                data.list = this.list.map((item: SyncMemberVoucher) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.list !== undefined)
                writer.writeRepeatedMessage(1, this.list, (item: SyncMemberVoucher) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncMemberVouchers {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncMemberVouchers();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SyncMemberVoucher.deserialize(reader), SyncMemberVoucher));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncMemberVouchers {
            return SyncMemberVouchers.deserialize(bytes);
        }
    }
    export class SyncMemberVoucher extends pb_1.Message {
        constructor(data?: any[] | {
            mv_id?: number;
            sid?: number;
            uid?: number;
            voucher_id?: number;
            title?: string;
            price?: number;
            voucher_value?: number;
            expire_at?: number;
            write_off_at?: number;
            delete_at?: number;
            update_at?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], []);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("mv_id" in data && data.mv_id != undefined) {
                    this.mv_id = data.mv_id;
                }
                if ("sid" in data && data.sid != undefined) {
                    this.sid = data.sid;
                }
                if ("uid" in data && data.uid != undefined) {
                    this.uid = data.uid;
                }
                if ("voucher_id" in data && data.voucher_id != undefined) {
                    this.voucher_id = data.voucher_id;
                }
                if ("title" in data && data.title != undefined) {
                    this.title = data.title;
                }
                if ("price" in data && data.price != undefined) {
                    this.price = data.price;
                }
                if ("voucher_value" in data && data.voucher_value != undefined) {
                    this.voucher_value = data.voucher_value;
                }
                if ("expire_at" in data && data.expire_at != undefined) {
                    this.expire_at = data.expire_at;
                }
                if ("write_off_at" in data && data.write_off_at != undefined) {
                    this.write_off_at = data.write_off_at;
                }
                if ("delete_at" in data && data.delete_at != undefined) {
                    this.delete_at = data.delete_at;
                }
                if ("update_at" in data && data.update_at != undefined) {
                    this.update_at = data.update_at;
                }
            }
        }
        get mv_id() {
            return pb_1.Message.getField(this, 1) as number;
        }
        set mv_id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get sid() {
            return pb_1.Message.getField(this, 2) as number;
        }
        set sid(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get uid() {
            return pb_1.Message.getField(this, 11) as number;
        }
        set uid(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get voucher_id() {
            return pb_1.Message.getField(this, 3) as number;
        }
        set voucher_id(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get title() {
            return pb_1.Message.getField(this, 4) as string;
        }
        set title(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get price() {
            return pb_1.Message.getField(this, 5) as number;
        }
        set price(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get voucher_value() {
            return pb_1.Message.getField(this, 6) as number;
        }
        set voucher_value(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get expire_at() {
            return pb_1.Message.getField(this, 7) as number;
        }
        set expire_at(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get write_off_at() {
            return pb_1.Message.getField(this, 8) as number;
        }
        set write_off_at(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get delete_at() {
            return pb_1.Message.getField(this, 9) as number;
        }
        set delete_at(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get update_at() {
            return pb_1.Message.getField(this, 10) as number;
        }
        set update_at(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data: {
            mv_id?: number;
            sid?: number;
            uid?: number;
            voucher_id?: number;
            title?: string;
            price?: number;
            voucher_value?: number;
            expire_at?: number;
            write_off_at?: number;
            delete_at?: number;
            update_at?: number;
        }) {
            const message = new SyncMemberVoucher({});
            if (data.mv_id != null) {
                message.mv_id = data.mv_id;
            }
            if (data.sid != null) {
                message.sid = data.sid;
            }
            if (data.uid != null) {
                message.uid = data.uid;
            }
            if (data.voucher_id != null) {
                message.voucher_id = data.voucher_id;
            }
            if (data.title != null) {
                message.title = data.title;
            }
            if (data.price != null) {
                message.price = data.price;
            }
            if (data.voucher_value != null) {
                message.voucher_value = data.voucher_value;
            }
            if (data.expire_at != null) {
                message.expire_at = data.expire_at;
            }
            if (data.write_off_at != null) {
                message.write_off_at = data.write_off_at;
            }
            if (data.delete_at != null) {
                message.delete_at = data.delete_at;
            }
            if (data.update_at != null) {
                message.update_at = data.update_at;
            }
            return message;
        }
        toObject() {
            const data: {
                mv_id?: number;
                sid?: number;
                uid?: number;
                voucher_id?: number;
                title?: string;
                price?: number;
                voucher_value?: number;
                expire_at?: number;
                write_off_at?: number;
                delete_at?: number;
                update_at?: number;
            } = {};
            if (this.mv_id != null) {
                data.mv_id = this.mv_id;
            }
            if (this.sid != null) {
                data.sid = this.sid;
            }
            if (this.uid != null) {
                data.uid = this.uid;
            }
            if (this.voucher_id != null) {
                data.voucher_id = this.voucher_id;
            }
            if (this.title != null) {
                data.title = this.title;
            }
            if (this.price != null) {
                data.price = this.price;
            }
            if (this.voucher_value != null) {
                data.voucher_value = this.voucher_value;
            }
            if (this.expire_at != null) {
                data.expire_at = this.expire_at;
            }
            if (this.write_off_at != null) {
                data.write_off_at = this.write_off_at;
            }
            if (this.delete_at != null) {
                data.delete_at = this.delete_at;
            }
            if (this.update_at != null) {
                data.update_at = this.update_at;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.mv_id !== undefined)
                writer.writeInt64(1, this.mv_id);
            if (this.sid !== undefined)
                writer.writeInt64(2, this.sid);
            if (this.uid !== undefined)
                writer.writeInt64(11, this.uid);
            if (this.voucher_id !== undefined)
                writer.writeInt64(3, this.voucher_id);
            if (typeof this.title === "string" && this.title.length)
                writer.writeString(4, this.title);
            if (this.price !== undefined)
                writer.writeDouble(5, this.price);
            if (this.voucher_value !== undefined)
                writer.writeDouble(6, this.voucher_value);
            if (this.expire_at !== undefined)
                writer.writeInt64(7, this.expire_at);
            if (this.write_off_at !== undefined)
                writer.writeInt64(8, this.write_off_at);
            if (this.delete_at !== undefined)
                writer.writeInt64(9, this.delete_at);
            if (this.update_at !== undefined)
                writer.writeInt64(10, this.update_at);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SyncMemberVoucher {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SyncMemberVoucher();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.mv_id = reader.readInt64();
                        break;
                    case 2:
                        message.sid = reader.readInt64();
                        break;
                    case 11:
                        message.uid = reader.readInt64();
                        break;
                    case 3:
                        message.voucher_id = reader.readInt64();
                        break;
                    case 4:
                        message.title = reader.readString();
                        break;
                    case 5:
                        message.price = reader.readDouble();
                        break;
                    case 6:
                        message.voucher_value = reader.readDouble();
                        break;
                    case 7:
                        message.expire_at = reader.readInt64();
                        break;
                    case 8:
                        message.write_off_at = reader.readInt64();
                        break;
                    case 9:
                        message.delete_at = reader.readInt64();
                        break;
                    case 10:
                        message.update_at = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SyncMemberVoucher {
            return SyncMemberVoucher.deserialize(bytes);
        }
    }
}
