import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'
import notification from 'ant-design-vue/es/notification'
import store from '@/lib/store'
import router from '@/lib/router'

const request: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://cashier.mywsy.cn/web' : 'https://cashier.mywsy.cn/web',
  timeout: 5000,
  withCredentials: false,
  responseType: 'json',
  headers: {
    'X-Gdb': 'gdb'
  }
} as AxiosRequestConfig)

const errHandler = (err: unknown): Promise<unknown> => {
  const ae = (err as AxiosError)
  if (ae.response !== undefined) {
    const res = ae.response
    if (res.status === 403) {
      router.push({ name: 'login' }).catch(null)
      notification.error({
        message: res.data.error
      })
    } else if (res.status === 404) {
      return Promise.reject(err)
    } else {
      notification.error({
        message: res.data.error
      })
    }
  }
  return Promise.reject(err)
}

request.interceptors.request.use((config: AxiosRequestConfig) => {
  const session = store.state.session
  if (session.token.token) {
    config.headers ? config.headers.Authorization = session.token.token : config.headers = { Authorization: session.token.token }
  }
  if (session.id_cards_index >= 0) {
    const shop = session.id_cards[session.id_cards_index].shop
    config.headers ? config.headers['X-SID'] = `${shop.sid}` : config.headers = { 'X-SID': `${shop.sid}` }
  }
  return config
}, errHandler)

request.interceptors.response.use(r => {
  return r.data
}, errHandler)

export default request
