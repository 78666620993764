/**
 * 全局性的基础数据接口
 * 这是最基础的接口
 * 这是最高级的抽象
 * 这是所有业务代码的纲领性文件
 */

/**
 * 业务类型
 */
export enum SellType {
  Project = 0,
  CountingCard = 1,
  Product = 2,
  DiscountCard = 3,
  CardRecharge = 4,
  UseCountingCard = 6,
  TimeCard = 7,
  UseTimeCard = 8,
  IntegralCard = 9,
  Redeem = 10,
  SpecialPriceCard = 12,
  PrerogativeCard = 15,
  Repayment = 16,
}

export enum ShopIndustry {
  Service = '服务',
  Retail = '零售',
  Food = '餐饮',
  HairSalon = '美发',
  BeautySalon = '美容',
  OtherSalon = '美业其它'
}

export enum CashierVersion {
  Lite = '精简版',
  Standard = '标准版'
}

export enum Sex {
  unknown,
  man,
  woman
}

export enum ShopRole {
  Boss = 1,
  Employee,
  Member,
  Cashier
}

export enum ShopPluginType {
  Integral = 'integral'
}

/**
 * 门店表
 */
export interface IShop {
  readonly sid: number
  readonly shop_name: string
  readonly tel: string
  readonly province: string
  readonly city: string
  readonly district: string
  readonly addr: string
  readonly icon: string
  expiration: Date
  readonly open_sid: string
  readonly cashier_version: CashierVersion
  readonly industry: ShopIndustry
  readonly is_import_support: boolean
  readonly menu: SellType[]
  readonly plugin: ShopPluginType[]
}

/**
 * 成员表
 */
export interface IUser {
  readonly uid: number
  readonly sid: number
  readonly role: ShopRole
  readonly phone_number: string
  readonly real_name: string
  readonly name_key: string
}

/**
 * 会员
 */
export interface IMember extends IUser {
  readonly arrears: number
  readonly head_img_url?: string
  readonly last_buy_time: Date
  sex: Sex
  readonly wx_open_id?: string
}

/**
 * 微信粉丝
 */
export interface IWxFan {
  headimgurl: string
  nickname: string
  openid: string
  unionid: string
}

/**
 * 员工
 */
export interface IEmployee extends IUser {
  is_appoint: boolean
}

/**
 * 操作员
 */
export interface ICashier extends IUser {
  readonly privilege_data: number
}

/**
 * 操作员胸卡
 */
export interface IIdCard {
  shop: IShop
  creator: ICashier
}

/**
 * 口令
 */
export interface IToken {
  token: string
  expire: Date
}

/**
 * 管店宝账号
 */
export interface IAccount {
  phone_number: string
  did?: string
  open_aid: string
  web_wx_open_id?: string
}

/**
 * 会话
 */
export interface ISession {
  account: IAccount
  token: IToken
  id_cards: IIdCard[]
  id_cards_index: number
}

export const SellTypeName = {
  0: '项目',
  1: '次卡/套盒',
  2: '商品',
  3: '储值卡',
  4: '储值卡充值',
  6: '划次卡',
  7: '时段卡',
  8: '划时段卡',
  9: '积分卡',
  10: '积分兑换',
  11: '积分充值',
  12: '会员价储值卡',
  14: '附加费',
  15: '折扣特权卡',
  16: '还款'
}

/**
 * 价目表单项
 */
export interface ICommodity {
  snid: number
  sid: number
  sell_type_id: SellType
  commodity: string
  unit_price: number
  frequency: number
  volume: number
  months: number
  name_key: string
  gift_amount: number
}

/**
 * 会员卡
 */
export interface IMemberCard {
  mpid: number
  sid: number
  uid: number
  bid: number
  sell_type_id: SellType
  card_name: string
  frequency: number
  frequency_balance: number
  money_balance: number
  use_percent: number
  expire_at: Date
  integral: number
  dc_pay_range: { [key: number]: number[] }
  buy_types_limit: SellType[]
  creator_uid: number
}

/**
 * 票据业务项
 */
export interface IBillItem {
  amount: number
  commodity: string
  frequency: number
  sell_type_id: SellType
  sid: number
  snid: number
  unit_price: number
  gift_amount: number
  frequency_limit: number
  months: number
  integral: number
  range: string
  rangeObj: { [key: number]: number[] }
  buy_types_limit: number[]
}

/**
 * 收款类型
 */
export interface IBillPay {
  readonly id: number
  readonly typeName: string
  fee: number
  limit: number
}

/**
 * 电子票据
 */
export interface IBill extends IIdCard {
  member: IUser
  items: IBillItem[]
  pays: IBillPay[]
  pay_fees: number
  cash_back: number
  integral: number
  employees: IEmployee[]
  repayment_bid: number
}

/**
 * 出票结果
 */
export interface IBillRes {
  bid: number
  open_bid: string
}

/**
 * 数据同步接口
 */
export interface ICacheUser extends IMember, ICashier {
  readonly nick_name?: string
  update_at: Date
  readonly delete_at?: Date
}

export interface ICacheCommodity extends ICommodity {
  update_at: Date
  readonly delete_at?: Date
}

export interface ICacheMemberCards extends IMemberCard {
  update_at: Date
  readonly delete_at?: Date
}

export interface ICacheBill {
  bid: number
  open_bid: string
  sid: number
  creator_uid: number
  buyer_uid: number
  pay_fees: number
  sell_type_id: SellType
  member_card_id: number
  is_set: boolean
  arrears: number
  repayment_bid: number
  mark: string
  integral: number
  is_female: boolean
  attach_pic: string
  member_card_fees: number
  create_at: Date
  update_at: Date
  delete_at?: Date
}

export interface ICacheVoucher {
  mv_id: number
  sid: number
  uid: number
  voucher_id: number
  title: string
  price: number
  voucher_value: number
  expire_at: Date
  write_off_at?: Date
  update_at: Date
  delete_at?: Date
}

export interface IGift {
  ifid: number
  sid: number
  title: string
  integral: number
}
