export enum ApiInterface {
  SmsCaptcha = 'sms',
  Login = 'login',
  WxAuth = 'wx/auth',
  TokenRefresh = 'token/refresh',
  MemberAdd = 'user/member',
  Bill = 'bill',
  MemberCardLimit = 'member_card/limit',
  Gifts = 'integral/gifts',
  Commodity = 'commodity',
  SyncUser = 'sync/users',
  SyncCommodities = 'sync/commodities',
  SyncMemberPockets = 'sync/member_pockets',
  SyncBills = 'sync/bills',
  SyncMemberVouchers = 'sync/member_vouchers'
}

export abstract class ApiInterfaceMatch {
  static readonly SmsCaptcha: RegExp = /sms/
  static readonly MemberAdd: RegExp = /member/
  static readonly Employees: RegExp = /employees/
}

export interface ILoginForm {
  phone_number: string
  code: string
  wx_open_id: string
}

export interface IMemberAddForm {
  phone_number: string
  real_name: string
  sex: number
  sid: number
}

export type ApiMock = [RegExp, string, unknown]
