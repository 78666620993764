export const printWelcome = (): void => {
  // ASCII - ANSI Shadow
  const text = `
 ██████╗ ██████╗ ██████╗
██╔════╝ ██╔══██╗██╔══██╗
██║  ███╗██║  ██║██████╔╝
██║   ██║██║  ██║██╔══██╗
╚██████╔╝██████╔╝██████╔╝
 ╚═════╝ ╚═════╝ ╚═════╝
mywsy.com`
  console.log(`%c${text}`, 'color: #fc4d50')
}
