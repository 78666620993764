import { RouteRecordRaw } from 'vue-router'
import LoginFormView from './LoginFormView.vue'
import IdCardsView from './IdCardsView.vue'

export default <Array<RouteRecordRaw>>[
  {
    path: '',
    redirect: { name: 'login' }
  },
  {
    path: 'login',
    name: 'login',
    meta: { requireAuth: false },
    component: LoginFormView
  },
  {
    path: 'ids',
    name: 'pickIds',
    meta: { requireAuth: false },
    component: IdCardsView
  }
]
