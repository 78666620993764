import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'
import layouts from '@/layouts'
import store from '@/lib/store'

const routes: Array<RouteRecordRaw> = [
  ...layouts,
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'notfound' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  // 只做登录守卫
  if (to.meta.requireAuth) {
    if (store.state.session.id_cards.length === 0 || store.state.session.token.token === '') {
      next({ name: 'login' })
    } else if (store.state.session.id_cards_index === -1) {
      next({ name: 'pickIds' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
