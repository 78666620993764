import { ApiInterface, ApiInterfaceMatch, ApiMock, IMemberAddForm } from '@/api/typings'
import request from '@/lib/request'
import { AxiosRequestConfig } from 'axios'
import { IBill, IBillRes, ICommodity, IGift, IMember, IMemberCard } from '@/interface'

export class ApiCashier {
  /**
   * 添加会员
   * @param form
   */
  static memberAdd (form: IMemberAddForm): Promise<IMember> {
    return request.request({
      url: ApiInterface.MemberAdd,
      method: 'post',
      data: form
    } as AxiosRequestConfig)
  }

  static memberAddMock = (member: IMember): ApiMock => {
    return [ApiInterfaceMatch.MemberAdd, 'post', member]
  }

  /**
   * 更新会员卡优惠限制
   */
  static memberCardLimit (mc: IMemberCard): Promise<unknown> {
    return request.request({
      url: ApiInterface.MemberCardLimit,
      method: 'put',
      data: mc
    } as AxiosRequestConfig)
  }

  /**
   * 添加价目项
   * @param commodity
   */
  static newCommodity (commodity: ICommodity): Promise<ICommodity> {
    return request.request({
      url: ApiInterface.Commodity,
      method: 'post',
      data: commodity
    } as AxiosRequestConfig)
  }

  /**
   * 获取积分礼物
   */
  static gifts (): Promise<IGift[]> {
    return request.request({
      url: ApiInterface.Gifts,
      method: 'get'
    })
  }

  /**
   * 出票
   */
  static bill (bill: IBill): Promise<IBillRes> {
    return request.request({
      url: ApiInterface.Bill,
      method: 'post',
      data: bill
    } as AxiosRequestConfig)
  }
}
