import * as mqtt from 'mqtt'
import {
  MqttClient,
  OnConnectCallback,
  OnMessageCallback
} from 'mqtt'
import { IDisconnectPacket, IPublishPacket } from 'mqtt-packet'
import { IClientOptions, OnDisconnectCallback, OnErrorCallback } from 'mqtt/types/lib/client'
import sync from '@/api/http/sync'
import { v4 } from 'uuid'
import { IIdCard } from '@/interface'
import store from '@/lib/store'

export declare type OnEventCallback = (packet: IPublishPacket) => void

class MQTT {
  c?: MqttClient
  private broker = 'wss://broker.mywsy.cn:1885'
  private subs = new Map<string, OnEventCallback>()
  private username = ''
  private password = ''

  constructor () {
    setTimeout(() => {
      if (store && store.state.session.id_cards.length > 0 && new Date(store.state.session.token.expire) > new Date()) {
        this.connect(store.state.session.account.phone_number, store.state.session.token.token)
      }
    }, 1000)
  }

  connect (user: string, pwd: string): void {
    this.username = user
    this.password = pwd
    this.c = mqtt.connect(this.broker, {
      clientId: `web/${v4()}`,
      protocolVersion: 5,
      clean: true,
      username: user,
      password: pwd
    } as IClientOptions)
    this.c.on('connect', this.handleConnect)
    this.c.on('message', this.handleMessage)
    this.c.on('disconnect', this.handleDisconnect)
    this.c.on('error', this.handleError)
    this.c.on('close', this.handleClose)
    this.c.on('reconnect', this.handleReconnect)
  }

  private handleConnect: OnConnectCallback = () => {
    this.subShopChange(store.state.session.id_cards)
    this.subs.forEach((i, k) => this.sub(k, i))
  }

  private handleMessage: OnMessageCallback = (topic: string, payload: Buffer, packet: IPublishPacket): void => {
    console.log('handleMessage', topic, payload.length, packet)
    const call = this.subs.get(topic)
    if (call !== undefined) call(packet)
  }

  private handleDisconnect: OnDisconnectCallback = (packet: IDisconnectPacket): void => {
    console.log(packet)
  }

  private handleError: OnErrorCallback = (error: Error): void => {
    console.log(error)
  }

  private handleClose: OnErrorCallback = (): void => {
    console.log('close')
  }

  private handleReconnect = (): void => {
    console.log('handleReconnect')
  }

  sub (topic: string, callback: OnEventCallback) {
    this.subs.set(topic, callback)
    if (this.c && this.c.connected) this.c.subscribe(topic, { qos: 1 })
  }

  subShopSync (sid: number): void {
    this.sub(`event/shop/${sid}/sync/users/proto`, sync.handleUserChange)
    this.sub(`event/shop/${sid}/sync/commoditys/proto`, sync.handleCommodityChange)
    this.sub(`event/shop/${sid}/sync/member_pockets/proto`, sync.handleMemberCardChange)
    this.sub(`event/shop/${sid}/sync/bills/proto`, sync.handleBillChange)
    this.sub(`event/shop/${sid}/sync/member_vouchers/proto`, sync.handleVoucherChange)
  }

  subShopChange (ids: IIdCard[]): void {
    ids.forEach(id => {
      this.sub(`event/shop/${id.shop.sid}/sync/shops/json`, sync.handleShopChange)
    })
  }
}

export default new MQTT()
