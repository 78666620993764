import Dexie, { Table } from 'dexie'
import { ICacheBill, ICacheCommodity, ICacheMemberCards, ICacheUser, ICacheVoucher } from '@/interface'

export class Db extends Dexie {
  users!: Table<ICacheUser>
  commodities!: Table<ICacheCommodity>
  memberCards!: Table<ICacheMemberCards>
  bills!: Table<ICacheBill>
  vouchers!: Table<ICacheVoucher>

  constructor () {
    super('cashier')
    this.version(1).stores({
      users: 'uid, sid, role, sex, device_id, phone_number, real_name, wx_open_id, head_img_url, nick_name, privilege_data, arrears, name_key, last_buy_time, update_at, delete_at',
      commodities: 'snid, sid, sell_type_id, commodity, unit_price, frequency, volume, months, name_key, update_at, gift_amount, delete_at',
      memberCards: 'mpid, sid, uid, sell_type_id, card_name, frequency, frequency_balance, money_balance, bid, expire_at, integral, dc_pay_range, update_at, buy_types_limit, use_percent, delete_at',
      bills: 'bid, open_bid, sid, creator_uid, buyer_uid, pay_fees, sell_type_id, member_card_id, is_set, arrears, repayment_bid, mark, create_at, update_at, integral, is_female, attach_pic, member_card_fees, delete_at',
      vouchers: 'mv_id, sid, uid, voucher_id, title, price, voucher_value, expire_at, write_off_at, update_at, delete_at'
    })
  }
}

export default new Db()
