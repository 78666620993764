import { createApp } from 'vue'
// import './lib/mock'
import App from './App.vue'
import antd from 'ant-design-vue'
import './registerServiceWorker'
import router from './lib/router'
import store from './lib/store'
import './lib/db'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import '@/api/mqtt'

const app = createApp(App)
Sentry.init({
  app,
  dsn: 'https://ac1e9e7d0d574cb68fef5d10a71b96f3@o1122643.ingest.sentry.io/6160084',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['b.mywsy.cn', 'cashier.mywsy.cn', 'home.wangjunfeng.com.cn', /^\//]
    })
  ],
  tracesSampleRate: 1.0
})
app.use(store).use(router).use(antd).mount('#app')
