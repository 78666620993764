import { RouteRecordRaw } from 'vue-router'
import Layout from './WorkspaceLayout.vue'
import views from './views'

export default <Array<RouteRecordRaw>>[
  {
    path: '/',
    meta: { requireAuth: true },
    component: Layout,
    children: views
  }
]
